import React from 'react';
import Navbar from '../Components/Navbar/Navbar';
import Demo from '../Pages/Demo/Demo';

export default function IndexLayout() {
    return (
        <div className='section-bg-color'>
            <Navbar />
            <div className='espaciobg'>
            </div>
            <Demo />
        </div>
    );
}
